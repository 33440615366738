$product-price-value-old-color: color(gray-dark);
$product-price-value-new-color: color(primary);

@mixin master-components-product-price {
    .c-product-main__price {
        .c-product-price__value {
            &.m-new {
                color: color(primary);
            }
        }
    }
}

@import "@lora/05-components/product/product-price"; //stylelint-disable-line no-invalid-position-at-import-rule