
                            $is-app_ng_anz-enabled: true;
                            @import '../../sass-inline-svg';
$form-field-shipping-anz-selection-title-max-height: rem-calc(20);
$form-field-shipping-anz-address-search-icon: magnifying-glass-icon;
$form-field-shipping-anz-address-search-icon-color: rgba(255, 255, 255, 0);
$form-field-shipping-anz-address-search-icon-stroke-color: color(text-secondary);
$form-field-shipping-anz-address-search-icon-position: 0;
$form-field-shipping-anz-address-search-icon-size: contain;
$form-field-shipping-anz-address-search-icon-height: rem-calc(20);
$form-field-shipping-anz-address-search-icon-width: rem-calc(20);
$form-field-shipping-anz-address-search-icon-transform: translate(20%, 20%);

@mixin anzlayer-components-address-field-section {
    //stylelint-disable
    .l-account_addressform, 
    .c-ngcheckout__shippingaddress,
    .c-ngcheckout__billingaddress {
        .formfield_address_suggestion.c-text-field__input ~ .m-float.c-text-field__label::after {
            content: '';
                @include inline-svg-icon($form-field-shipping-anz-address-search-icon, $form-field-shipping-anz-address-search-icon-color, $form-field-shipping-anz-address-search-icon-position, $form-field-shipping-anz-address-search-icon-size, no-repeat, $form-field-shipping-anz-address-search-icon-color, $form-field-shipping-anz-address-search-icon-stroke-color);
                display: inline-block;
                height: $form-field-shipping-anz-address-search-icon-height;
                width: $form-field-shipping-anz-address-search-icon-width;
                transform: $form-field-shipping-anz-address-search-icon-transform;
        }

        .m-active > .formfield_address_suggestion.c-text-field__input ~ .m-float.c-text-field__label::after,
        .m-focus > .formfield_address_suggestion.c-text-field__input ~ .m-float.c-text-field__label::after,
        .formfield_address_suggestion.c-text-field__input:read-only ~ .m-float.c-text-field__label::after {
            display: none;
        }
    }
}
                        