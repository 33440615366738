$header-navigation-link-level-1-font-family: $font-primary-bold;
$header-navigation-link-level-1-color: color(dark);
$header-navigation-link-level-1-color--large: color(light);
$header-navigation-link-level-1-hover-color: color(light);
$header-navigation-link-level-1-hover-color--large: color(light);
$header-navigation-link-level-1-font-weight: 600;
$header-navigation-link-level-1-font-weight--large: 600;
$header-navigation-link-level-1-hover-font-weight: 600;
$header-navigation-link-level-1-font-size--large: rem-calc(14);
$header-navigation-item-title-level-1-expanded-background: color(light);
$header-navigation-item-title-level-1-padding-left: rem-calc(20);
$header-navigation-item-title-level-2-background: color(hamburger-background);
$header-navigation-item-title-level-2-padding-left: rem-calc(40);
$header-navigation-link-level-2-color: color(dark);
$header-navigation-item-title-level-2-expanded-background: color(hamburger-background);
$header-navigation-item-level-2-all-link-text-transform--large: uppercase;
$header-navigation-link-level-3-padding--medium-down: rem-calc(10 0 10 60);
$header-navigation-item-title-level-3-background: color(hamburger-background);
$header-navigation-link-level-3-color: color(gray-dark);
$header-navigation-link-text-transform: uppercase;
$header-navigation-link-font-size: rem-calc(14);
$header-navigation-flyout-element-background--large-down: color(hamburger-background);
$header-navigation-item-title-accordion-icon-color: color(user-menu-color);
$header-navigation-item-title-accordion-icon: 'chevron-menu-mobile';
$header-navigation-flyout-element-background--large: color(light);
$header-navigation-flyout-element-box-shadow--large: rem-calc(0 0 2 0) color(border);
$header-navigation-flyout-element-margin-left--large: rem-calc(-23);
$header-navigation-item-level-3-all-link-font: 500 #{rem-calc(14)} / 1.6 $font-primary;
$header-navigation-item-level-3-all-link-font--large: 500 #{rem-calc(14)} / 1.6 $font-primary;
$header-navigation-item-title-level-2-font-size--large: rem-calc(14);
$header-navigation-item-title-level-2-font-weight--large: 500;
$header-navigation-list-level-3-margin--large: rem-calc(30 0 0);
$header-navigation-item-level-3-all-link-text-decoration: none;
$header-navigation-item-level-3-all-link-text-decoration--large: underline;
$header-navigation-link-level-2-text-transform: none;
$header-navigation-item-level-3-all-link-color--large: color(organism-background);
$header-navigation-link-level-1-text-transform: none;
$header-navigation-link-level-3-color--large: color(gray-dark);
$header-navigation-item-title-level-3-font-size--large: rem-calc(14);
// Link image
$header-navigation-link-image-margin: rem-calc(-6 0 -6 0);

$header-navigation-link-level-1-text-transform--large: uppercase;
$header-navigation-item-level-3-all-link-text-transform: none;
$header-navigation-link-level-3-text-transform: none;
$header-navigation-link-level-2-font-size--large: rem-calc(14);
$header-navigation-link-level-3-font-size: rem-calc(14);
$header-navigation-link-level-3-font-size--large: rem-calc(14);
$header-navigation-link-level-3-font-weight--large: 300;
$header-navigation-link-level-1-padding--large: rem-calc(14 24 14 0);
$header-navigation-item-level-2-all-link-text-decoration: underline;
$header-navigation-item-level-2-border: none;

@import "@lora/05-components/header/header-navigation";