
                            $is-app_ng_anz-enabled: true;
                            $ordergroove-common-selector-icon: questionmark !default;
$ordergroove-common-color: color(primary) !default;
$ordergroove-common-height: rem-calc(22) !default;
$ordergroove-common-width: rem-calc(20) !default;
$ordergroove-common-selector-icon-size: rem-calc(15 15) !default;
$ordergroove-common-selector-icon-left: rem-calc(160) !default;
$ordergroove-common-selector-left: rem-calc(145) !default;

@mixin anzlayer-components-ordergroove {
    .c-order-groove__wrapper {
        position: relative;

        &.m-secondary {
            position: absolute;
            left: $ordergroove-common-selector-left;
        }

        .c-order-groove-selector__icon {
            position: absolute;
            @include svg-icon($ordergroove-common-selector-icon, $ordergroove-common-color, $ordergroove-common-selector-icon-size, left center, no-repeat);

            display: inline-block;
            width: $ordergroove-common-width;
            height: $ordergroove-common-height;
            left: $ordergroove-common-selector-icon-left;

            .m-questionmark {
                width: $ordergroove-common-width;
                height: $ordergroove-common-height;
            }
        }
    }
}

                        