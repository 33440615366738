$product-tile-horizontal-actions-padding: 0;
$product-tile-horizontal-figure-margin--large: rem-calc(0 30 0 0);
$product-tile-horizontal-figure-margin: rem-calc(0 10 0 0);
$product-tile-horizontal-info-item-font-weight--large: 500;
$product-tile-horizontal-info-item-font: #{rem-calc(16)} / 1.43 $font-primary;
$product-tile-horizontal-info-justify-content: space-between;
$product-tile-horizontal-name-font-size: rem-calc(14);
$product-tile-horizontal-name-font-weight: 600;
$product-tile-horizontal-name-line-height: 1;
$product-tile-horizontal-name-link-text-transform: uppercase;
$product-tile-horizontal-name-margin--large: rem-calc(0 0 8);
$product-tile-horizontal-name-max-lines--large: 2;
$product-tile-horizontal-name-max-lines: 2;
$product-tile-horizontal-name-text-transform: uppercase;
$product-tile-horizontal-price-font: 600 #{rem-calc(16)} / 1.25 $font-primary;
$product-tile-horizontal-secondary-figure-width: rem-calc(60);
$product-tile-horizontal-subtitle-font-size--large: rem-calc(14);
$product-tile-horizontal-subtitle-font-size: rem-calc(14);
$product-tile-horizontal-subtitle-max-lines--large: 3;
$product-tile-horizontal-subtitle-max-lines: 3;
$product-tile-horizontal-table-margin: 0;
$product-tile-horizontal-variation-margin--large: rem-calc(25 0 15);
$product-tile-horizontal-variation-margin: rem-calc(16 0 12);
$product-tile-horizontal-variations-padding--large: rem-calc(0 0 0 20);
$product-tile-horizontal-quantity-justify-content--large: center;

@import "@lora/05-components/product/product-tile-horizontal";