
                            $is-app_ng_anz-enabled: true;
                            $account-offline-registration-display--large: none !default;
$account-offline-modal-justify-content: center !default;
$account-offline-modal-padding: rem-calc(10 0) !default;

@mixin anzlayer-components-account-offline {
    .c-account-offline__registration {
        .c-offline-account__activate,
        .c-sociallogin {
            @include breakpoint(large) {
                display: $account-offline-registration-display--large;
            }

            + .c-offline-account,
            ~ .c-separator {
                @include breakpoint(large) {
                    display: $account-offline-registration-display--large;
                }
            }
        }
    }

    .c-offline-account-modal,
    .c-offline-account__wrapper {
        .m-v2 {
            justify-content: $account-offline-modal-justify-content;
            padding: $account-offline-modal-padding;
        }
    }
}

                        