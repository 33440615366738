$form-check-field-label-font-size: rem-calc(14);
$form-check-field-label-height: 1.43;

// Radiobuttons
$form-check-field-radio-control-background: color(dark);
$form-check-field-radio-control-after-border: rem-calc(1) solid color(border);
$form-check-field-radio-control-after-size: rem-calc(20);
$form-check-field-radio-control-after-top: rem-calc(1);
$form-check-field-radio-checked-control-hover-box-shadow: none;
$form-check-field-radio-checked-control-focus-box-shadow: none;
$form-check-field-radio-checked-control-active-box-shadow: none;

// Checkbox
$form-check-field-checkbox-control-border: 1px solid color(organism-background);
$form-check-field-checkbox-checked-control-background: transparent;
$form-check-field-checkbox-checked-control-border: 1px solid color(organism-background);
$form-check-field-checkbox-checked-icon-color: color(organism-background);
$form-check-field-checkbox-checked-icon-position--top: rem-calc(5);
$form-check-field-checkbox-checked-icon: checkmark;
$form-check-field-disabled-control-background: transparent;

// Error state
$form-check-field-error-control-color: color(alert);

@import "@lora/05-components/form/check-field";