$product-tile-grid-name-text-align: start;
$product-tile-grid-description-text-align: $product-tile-grid-name-text-align;
$product-tile-grid-info-item-align-self: $product-tile-grid-name-text-align;
$product-tile-grid-caption-padding: rem-calc(0 15 0 10);
$product-tile-name-text-transform: uppercase;
$product-tile-name-line-height: 1;
$product-tile-description-color: color(text);
$product-tile-grid-figure-padding: rem-calc(0 10 50 0);
$product-tile-add-bag-button-min-width--large: 100%;
$product-tile-variations-width--large: 100%;
$product-tile-info-item-align: center;
$product-tile-info-item-align--large: center;
$product-tile-name-font-weight: 700;
$product-tile-name-line-height: 1;
$product-tile-description-font-size: rem-calc(12);
$product-tile-description-font-size--large: $product-tile-description-font-size;
$product-tile-description-line-height--large: 1;
$product-tile-info-separator: none;
$product-tile-price-font: 400 #{rem-calc(14)} / 1.25 $font-primary;
$product-tile-price-font--large: 400 #{rem-calc(14)} / 1.25 $font-primary;
$product-tile-name-font-size: rem-calc(14);
$product-tile-name-font-size--large: $product-tile-name-font-size;
$product-tile-name-font-family: $font-primary-bold;
$product-tile-button-margin: rem-calc(20 0 0);
$product-tile-swatch-selected-color: color(dark);
$product-tile-swatch-selected-font-size: rem-calc(12);
$product-tile-swatch-selected-font-size--large: $product-tile-swatch-selected-font-size;
$product-tile-swatch-selected-font-weight: 100;
$product-tile-name-line-height--large: 1;
$product-tile-info-min-height: rem-calc(30);
$product-tile-info-min-height-multiple-lines: rem-calc(56);
$product-tile-variation-group-height: rem-calc(50);
$product-tile-variation-group-height--large: $product-tile-variation-group-height;
$product-tile-actions-placeholder-height: rem-calc(50);
$product-tile-actions-margin--large: rem-calc(12 0 0);
$product-tile-actions-margin: rem-calc(12 0 0);
$product-tile-name-color-visited: color(text);
$product-tile-add-bag-text-font: inherit;
$product-tile-add-bag-text-transform: inherit;
$product-tile-default-text-align: center;
$product-tile-rating-align: center;
$product-tile-rating-align--large: $product-tile-rating-align;
$product-tile-variations-label-color: color(gray-dark);

@import "@lora/05-components/product/product-tile";