/*------------------------------------*\
        #ERROR PAGE
\*------------------------------------*/
$error-callout-text-align: left;
$error-callout-title-font: 900 #{rem-calc(24)} / 1 $font-primary;
$error-callout-title-font--large: 900 #{rem-calc(36)} / 1 $font-primary;
$error-callout-title-margin: rem-calc(0 0 10);
$error-callout-title-margin--large: rem-calc(10 0 15);
$error-callout-title-color: color(dark);
$error-callout-subtitle-font: #{rem-calc(14)} / 1.43 $font-primary;
$error-callout-subtitle-margin: rem-calc(0 0 20);
$error-callout-subtitle-font--large: #{rem-calc(16)} / 1.3 $font-primary;
$error-callout-subtitle-margin--large: rem-calc(0 0 25);
$error-callout-subtitle-text-transform: none;
$error-callout-subtitle-color: color(dark);
$error-callout-padding: rem-calc(30 25 10);
$error-callout-padding--large: rem-calc(80 15);
$error-callout-info-font: #{rem-calc(14)} / 1.43 $font-primary;

@mixin master-components-error-callout {
    .c-error-callout {
        @include breakpoint(large) {
            @include layout;

            text-align: center;
        }

        .l-section__row & {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .c-error-callout__title {
        color: $error-callout-title-color;
    }

    .c-error-callout__subtitle,
    .c-error-callout__info {
        color: $error-callout-subtitle-color;
    }
}

@import "@lora/05-components/error-callout"; //stylelint-disable-line no-invalid-position-at-import-rule