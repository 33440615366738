$modal-wrapper-padding--large: rem-calc(48 84 60 84);
$modal-wrapper-padding: rem-calc(60 25 25);
$modal-body-font: 300 #{rem-calc(16)} / 1.25 $font-primary;
$modal-small-wrapper--medium: rem-calc(470);
$modal-wrapper-width--large: rem-calc(800);

// Title
$modal-title-margin: rem-calc(0 0 17);
$modal-title-margin--large: rem-calc(0 0 15);
$modal-title-font: bold #{rem-calc(24)} / 1 $font-primary-bold;
$modal-title-font--large: bold #{rem-calc(36)} / 1 $font-primary-bold;
// Subtitle
$modal-subtitle-margin: rem-calc(0 0 10);
$modal-subtitle-margin--large: rem-calc(0 0 10);
$modal-subtitle-font: 500 #{rem-calc(18)} / 1 $font-primary;
$modal-subtitle-font--large: 500 #{rem-calc(18)} / 1 $font-primary;
$modal-subtitle-text-transform: uppercase;
$modal-subtitle-text-transform--large: uppercase;
// Text
$modal-text-margin: rem-calc(0 0 60);
$modal-text-margin--large: rem-calc(0 0 50);
$modal-text-font-size: rem-calc(16);
$modal-text-large-font-size: rem-calc(16);
$modal-text-line-height: 1.25;
$modal-text-font-weight: 300;
$modal-text-large-font-weight: 300;
// Modal with image
$modal-image-padding: rem-calc(50 25 25);
$modal-image-content-padding--large: rem-calc(80 100);
// Close icon
$modal-close-icon-color: color(primary);
$modal-close-icon-size: rem-calc(12);
$modal-close-icon-size--large: rem-calc(18);

// Small and medium width
$modal-large-wrapper--medium: rem-calc(1130);
$modal-medium-wrapper--medium: rem-calc(670);

@import "@lora/05-components/modal";