$form-text-field-radius: 0;
$form-text-field-border: 0;
$form-text-field-border-hover: 0;
$form-text-field-border-focus: 0;
$form-text-field-box-shadow: rem-calc(0 1 0 0) color(border);
$form-text-field-box-shadow-hover: $form-text-field-box-shadow;
$form-text-field-box-shadow-focus: $form-text-field-box-shadow;
$form-text-field-padding: rem-calc(0 12.8);
$form-text-field-height: rem-calc(50);
$form-text-field-font-size: rem-calc(14);
$form-text-field-font-weight: 500;
$form-text-field-line-height: 1.43;
$form-text-field-color-hover: color(text);
$form-text-field-font-weight-hover: 500;
$form-text-field-placeholder-color: color(organism-background);
// Requirements item
$form-text-field-requirements-item-font: #{rem-calc(14)} / 1 $font-primary;
/// Requirements icon
$form-text-field-requirements-item-icon-color: color(form-gray);
/// On validate error
$form-text-field-requirements-on-validate-item-error-color: color(form-gray);
$form-text-field-requirements-on-validate-item-error-icon-color: color(form-gray);
@import "@lora/05-components/form/text-field";