// Titles
$content-subtitle-font: #{rem-calc(18)} / 1 $font-primary;
$content-description-font-size: rem-calc(14);
$content-description-font-size--large: rem-calc(16);

@mixin master-components-content {
    .l-content__section {
        .c-select__label {
            &.m-float {
                margin-left: 0;
            }
        }
    }
}
@import "@lora/05-components/content/content"; //stylelint-disable-line no-invalid-position-at-import-rule