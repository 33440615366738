$header-simple-search-button-height--large: rem-calc(21);
$header-simple-search-button-width--large: rem-calc(20);
$header-simple-search-button-icon-color--large: color(dark);
$header-simple-search-button-margin--large: rem-calc(4 20 0 0);
$header-simple-search-label-font-size: rem-calc(36);
$header-simple-search-label-font-weight: 500;
$header-simple-search-label-color: color(organism-background);
$header-simple-search-label-font-family: $font-primary;
$header-simple-search-background--large: transparent;
$header-simple-search-field-box-shadow: none;

$header-simple-search-secondary-button-width--large: rem-calc(20);
$header-simple-search-secondary-input-group-width--large: rem-calc(198);
$header-simple-search-secondary-field-padding--large: rem-calc(0 15 0 20);

@import "@lora/05-components/header/header-simple-search";