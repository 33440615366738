$cart-box-margin: rem-calc(30 0);
$cart-box-inner-margin: rem-calc(30 15);
$cart-box-text-align: left;
$cart-box-margin--large: rem-calc(0 0 20);
$cart-box-payment-list-margin: rem-calc(30 0);
$cart-box-title-margin: rem-calc(0 0 17);
$cart-box-title-font: bold #{rem-calc(24)} / 1 $font-primary-bold;
$cart-box-p-font-size: rem-calc(16);
$cart-box-p-color: color(dark);
$cart-box-inner-link-font-size: rem-calc(16);
$cart-box-summary-inner-margin: rem-calc(42 30 37);
$cart-box-inner-link-text-decoration: underline;
$cart-box-inner-link-font-weight: 500;
$cart-box-inner-button-font-size: rem-calc(18);
$cart-box-inner-button-font-weight: 700;
$cart-box-payment-list-flex-justify-content: flex-start;
$cart-box-payment-list-li-flex: 0 0 20%;
$cart-box-payment-list-li-margin: rem-calc(5 5 5 0);

@mixin master-components-cart-box {
    .c-cart-box__inner {
        .c-button {
            font-size: $cart-box-inner-button-font-size;
            font-weight: $cart-box-inner-button-font-weight;
            text-decoration: none;
        }
    }
}
@import "@lora/05-components/cart/cart-box"; //stylelint-disable-line no-invalid-position-at-import-rule