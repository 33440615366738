$tabs-secondary-control-items-border-bottom: 1px solid color(organism-background);
$tabs-secondary-controls-background: transparent;
$tabs-secondary-content-background: transparent;
$tabs-tab-active-color: color(primary);
$tabs-tab-text-transform: uppercase;
$tabs-secondary-tab-padding--large: rem-calc(20 60);
$tabs-secondary-tab-font-size: rem-calc(18);
$tabs-secondary-tab-padding: rem-calc(15 40);
$tabs-tab-color: color(dark);
$tabs-secondary-content-font: 100 #{rem-calc(14)} / 1.29 $font-primary;
$tabs-secondary-content-padding: rem-calc(20);
$tabs-secondary-tab-active-color: color(primary);
$tabs-secondary-tab-font-size--large: $tabs-secondary-tab-font-size;
$tabs-secondary-content-font--large: 300 #{rem-calc(16)} / 1.25 $font-primary;
$tabs-control-items-border-bottom: none;
$tabs-tab-active-after-border-bottom: none;
$tabs-controls-align-self: left;
$tabs-tab-font: bold #{rem-calc(18)} / 1 $font-primary-bold;
$tabs-tab-font--large: bold #{rem-calc(24)} / 1 $font-primary-bold;
$tabs-tab-active-font--large: $tabs-tab-font--large;
$tabs-tab-margin: 0;
$tabs-tab-margin--large: rem-calc(0 10 0 5);
$tabs-tab-padding--large: rem-calc(15 120 19 0);
$tabs-tertiary-tab-text-transform: uppercase;
$tabs-tertiary-controls-background-color: color(light);
$tabs-tab-after-border-bottom: none;
$tabs-tab-after-width: 100%;
$tabs-tertiary-tab-font: 500 #{rem-calc(18)} / 1 $font-primary;
$tabs-tab-hover-color: color(primary-active);
$tabs-secondary-tab-hover-color: color(primary-active);
$tabs-secondary-controls-width--large: calc(100% + 30px);
$tabs-secondary-tab-divider-border-bottom-width: 1px;

@import "@lora/05-components/tabs";