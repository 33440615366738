$product-badge-title-color: color(light);
$product-badge-title-background: color(dark);
$product-badge-title-text-decoration: none;
$product-badge-title-text-transform: uppercase;
$product-badge-title-padding: rem-calc(3);
$product-badge-title-padding--large: rem-calc(3 10);
$product-badge-title-font: 400 #{rem-calc(14)} / 1 $PexicoMicroMono;
$product-badge-category-title-font: 400 #{rem-calc(14)} / 1 $PexicoMicroMono;
$product-badge-category-title-text-transform: uppercase;

@mixin master-components-product-badge() {
    .c-product-badge {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .c-product-badge__title {
            margin: 0 auto rem-calc(3) 0;
        }
    }
}

@import "@lora/05-components/product/product-badge"; //stylelint-disable-line no-invalid-position-at-import-rule