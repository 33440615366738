$add-to-wishlist-icon-color: color(dark);
$add-to-wishlist-border: 1px solid color(element-background);
$add-to-wishlist-border-radius: rem-calc(25);
$add-to-wishlist-padding: rem-calc(9);
$add-to-wishlist-icon-size: rem-calc(13);
$add-to-wishlist-icon-active-color: color(primary);
$add-to-wishlist-icon-size--large: rem-calc(16);
$add-to-wishlist-padding--large: rem-calc(8);
$add-to-wishlist-icon-active-hover-focus-color: color(dark);
$add-to-wishlist-box-shadow: none;

@import "@lora/05-components/add-to-wishlist";