$content-may-we-help-item-flex-direction: column;
$content-may-we-help-title-font-size: rem-calc(16);
$content-may-we-help-title-font-weight: 500;
$content-may-we-help-list-color: color(organism-background);
$content-may-we-help-list-line-height: 1;
$content-may-we-help-list-font-size: rem-calc(16);
$content-may-we-help-list-font-weight: 100;
$content-may-we-help-link-color: color(organism-background);
$content-may-we-help-item-padding: rem-calc(0 0 8);
$content-may-we-help-title-margin: rem-calc(0 0 20);
$content-may-we-help-item-icon-margin: rem-calc(0 0 10);

@import "@lora/05-components/content/content-may-we-help";