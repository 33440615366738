$header-hamburger-button-background: color(dark);
$header-hamburger-icon-color: color(light);
$header-hamburger-button-active-color: color(light);
$header-hamburger-button-padding: rem-calc(16 10 14);
$header-hamburger-icon-margin: rem-calc(0 10 0 5);
$header-hamburger-item-padding: rem-calc(0 18);
$header-hamburger-storeslink-icon-size: rem-calc(18 23);
$header-hamburger-asset-link-may-we-help-you-icon-size: rem-calc(20);
$header-hamburger-asset-link-why-shop-online-icon-size: rem-calc(20);
$header-hamburger-account-menu-icon-size: rem-calc(20);
$header-hamburger-item-font-size: rem-calc(14);
$header-hamburger-item-color: color(dark);
$header-hamburger-item-icon-color: color(dark);
$header-hamburger-item-link-transform: none;
$header-hamburger-icon-height: rem-calc(23);
$header-hamburger-content-alignment--large: left;
$header-hamburger-content-background: color(hamburger-background);

@import "@lora/05-components/header/header-hamburger";