$footer-sticky-bar-font: 300 #{rem-calc(14)} $font-primary;
$footer-sticky-bar-font--large: 300 #{rem-calc(14)} $font-primary;
$footer-sticky-bar-vertical-space: rem-calc(13);
$footer-sticky-bar-line-height: rem-calc(14);
$footer-sticky-bar-height: ($footer-sticky-bar-vertical-space * 2) + $footer-sticky-bar-line-height;
$footer-sticky-bar-height--large: $footer-sticky-bar-height;
$footer-sticky-bar-item-color: color(dark);
$footer-sticky-bar-item-color--medium: color(light);
$footer-sticky-bar-border: 1px solid color(dark);
$footer-sticky-bar-item-hover-color: color(primary);

@import "@lora/05-components/footer/footer-sticky-bar";