// NGL vars
$footer-contacts-title-margin: rem-calc(0 0 15);
$footer-contacts-title-font: 700 #{rem-calc(18)} / 0.89 $font-primary-bold;
$footer-contacts-title-font--large: $footer-contacts-title-font;
$footer-contacts-padding: rem-calc(10 0 30);
$footer-contacts-text-align--medium-down: left;
$footer-contacts-title-margin--large: rem-calc(0 0 25);
$footer-contacts-title-text-transform: uppercase;
$footer-contacts-title-text-transform--large: uppercase;
$footer-contacts-order: 1;
$footer-contacts-description-font: 300 #{rem-calc(16)} / 1.25 $font-primary;
$footer-contacts-link-simple-font: 300 #{rem-calc(16)} / 1.25 $font-primary;
$footer-contacts-link-margin: rem-calc(0 7);
$footer-contacts-link-padding: rem-calc(10 8);
$footer-contacts-link-background: color(dark);
$footer-contacts-link-justify-content: space-between;
$footer-contacts-link-text-decoration: underline;
$footer-contacts-link-font: 500 #{rem-calc(12)} / 1.5 $font-primary;
$footer-contacts-link-height: rem-calc(50);
$footer-contacts-link-radius: 0;
$footer-contacts-link-border: rem-calc(1) solid color(border);
$footer-contacts-link-phone-icon-height: rem-calc(20);
$footer-contacts-link-phone-icon-width: rem-calc(12);

// Master vars
$footer-contacts-brand-icon: "heart-tagline" !default;
$footer-contacts-brand-icon-color: color(primary) !default;
$footer-contacts-brand-icon-size: rem-calc(12) !default;
$footer-contacts-brand-icon-margin: rem-calc(4 4 -1) !default;
$footer-contacts-brand-color: color(light) !default;
$footer-contacts-brand-border-bottom: rem-calc(1) solid color(border) !default;
$footer-contacts-brand-border-bottom--large: none !default;
$footer-contacts-brand-padding: rem-calc(0 0 30) !default;
$footer-contacts-brand-padding--large: rem-calc(30 0 0) !default;
$footer-contacts-brand-margin: rem-calc(0 0 30) !default;
$footer-contacts-brand-margin--large: 0 !default;
$footer-contacts-brand-text-align: center !default;
$footer-contacts-brand-text-align--large: left !default;
$footer-contacts-brand-title-text-transform: uppercase !default;
$footer-contacts-brand-title-text-transform--large: uppercase !default;
$footer-contacts-brand-title-font: 500 #{rem-calc(18)} / 1 $font-primary !default;
$footer-contacts-brand-subtitle-text-transform: uppercase !default;
$footer-contacts-brand-subtitle-text-transform--large: uppercase !default;
$footer-contacts-brand-subtitle-font: 500 #{rem-calc(16)} / 1.25 $font-tertiary !default;
$footer-contacts-brand-subtitle-font--large: 500 #{rem-calc(16)} / 1.25 $font-tertiary !default;
$footer-contacts-brand-title-margin: rem-calc(0 0 10) !default;
$footer-contacts-brand-title-margin--large: $footer-contacts-brand-title-margin !default;
$footer-contacts-brand-subtitle-margin: rem-calc(0 0 10) !default;
$footer-contacts-brand-subtitle-margin--large: $footer-contacts-brand-subtitle-margin !default;

@mixin master-components-footer-contacts {
    .l-footer__contacts-brand {
        color: $footer-contacts-brand-color;
        text-align: $footer-contacts-brand-text-align;
        border-bottom: $footer-contacts-brand-border-bottom;
        padding: $footer-contacts-brand-padding;
        margin: $footer-contacts-brand-margin;

        @include breakpoint(large) {
            text-align: $footer-contacts-brand-text-align--large;
            border-bottom: $footer-contacts-brand-border-bottom--large;
            padding: $footer-contacts-brand-padding--large;
            margin: $footer-contacts-brand-margin--large;
        }
    }

    .l-footer__contacts-brand-title {
        text-transform: $footer-contacts-brand-title-text-transform;
        font: $footer-contacts-brand-title-font;
        margin: $footer-contacts-brand-title-margin;

        @include breakpoint(large) {
            text-transform: $footer-contacts-brand-title-text-transform--large;
            margin: $footer-contacts-brand-title-margin--large;
        }
    }

    .l-footer__contacts-brand-subtitle {
        text-transform: $footer-contacts-brand-subtitle-text-transform;
        font: $footer-contacts-brand-subtitle-font;
        margin: $footer-contacts-brand-subtitle-margin;

        @include breakpoint(large) {
            text-transform: $footer-contacts-brand-subtitle-text-transform--large;
            font: $footer-contacts-brand-subtitle-font--large;
            margin: $footer-contacts-brand-subtitle-margin--large;
        }

        &.m-icon::after {
            @include svg-icon($footer-contacts-brand-icon, $size: 100%, $position: center, $repeat: no-repeat, $color: $footer-contacts-brand-icon-color);

            content: '';
            display: inline-block;
            width: $footer-contacts-brand-icon-size;
            height: $footer-contacts-brand-icon-size;
            margin: $footer-contacts-brand-icon-margin;
        }
    }
}

@import "@lora/05-components/footer/footer-contacts"; //stylelint-disable-line no-invalid-position-at-import-rule