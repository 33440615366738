$footer-nav-list-level1-margin: rem-calc(30 0);
$footer-nav-list-level1-margin--large: rem-calc(50 0 30);
$footer-nav-link-level1-font: 700 #{rem-calc(18)} / 0.88 $font-primary-bold;
$footer-nav-link-level1-font--large: 700 #{rem-calc(14)} / 1 $font-primary-bold;
$footer-nav-link-level1-text-transform--large: uppercase;
$footer-nav-list-level2-margin--large: rem-calc(10 0 0);
$footer-nav-link-level2-font: 300 #{rem-calc(16)} / 1.25 $font-primary;
$footer-nav-link-level2-font--large: 300 #{rem-calc(14)} / 1 $font-primary;
$footer-nav-columns: 2 of 4;

@import "@lora/05-components/footer/footer-nav";