$minicart-background: color(transparent);
$minicart-empty-checkout-link-font: #{rem-calc(16)} / 1.25 $font-primary;
$minicart-empty-content-background: color(global-background);
$minicart-empty-content-width: 55%;
$minicart-empty-content-max-width: $minicart-empty-content-width;
$minicart-empty-recommendation-width: 45%;
$minicart-empty-recommendation-padding: rem-calc(70 10 70 20);
$minicart-empty-text-font: bold #{rem-calc(36)} / 1 $font-primary-bold;
$minicart-empty-width: rem-calc(670);
$minicart-actions-background: color(transparent);
$minicart-actions-padding: rem-calc(0 30 30);
$minicart-subtotal-background: color(transparent);
$minicart-subtotal-title-font: 500 #{rem-calc(16)} / 1.25 $font-primary;
$minicart-subtotal-title-text-transform: uppercase;
$minicart-subtotal-title-padding: rem-calc(30 30 17);
$minicart-subtotal-price-padding: rem-calc(30 15 17);
$minicart-subtotal-price-text-align: center;
$minicart-subtotal-price-font: 500 #{rem-calc(16)} / 1.25 $font-primary;
$minicart-th-font: bold #{rem-calc(24)} / 1 $font-primary-bold;
$minicart-th-qty-font: 500 #{rem-calc(16)} / 1.25 $font-primary;
$minicart-th-price-font: $minicart-th-qty-font;
$minicart-td-details-font: #{rem-calc(14)} / 1 $font-primary;
$minicart-td-qty-font: 500 #{rem-calc(16)} / 1.13 $font-primary;
$minicart-td-price-font: $minicart-td-qty-font;
$minicart-td-vertical-align: top;
$minicart-product-description-color: color(organism-background);
$minicart-product-title-font: 500 #{rem-calc(14)} / 1 $font-primary;
$minicart-product-title-text-transform: uppercase;
$minicart-remove-text-transform: capitalize;
$minicart-remove-font: 500 #{rem-calc(14)} / 1.14 $font-primary;
$minicart-remove-icon-display: false;
$minicart-product-title-max-lines: 3;

@import "@lora/05-components/minicart";