$header-background: color(dark);
$header-top-nav-item-color: color(light);
$header-search-button-background--large: color(light);
$header-search-button-color--large: color(dark);
$header-bottom-background--large: color(dark);
$header-search-button-icon-color: color(light);
$header-search-button-icon-color--large: color(dark);
$header-search-button-opened-color: color(dark);
$header-search-button-active-icon-color: $header-search-button-opened-color;
$header-search-button-border--large: 1px solid color(dark);
$header-search-button-padding: rem-calc(10 13);
$header-search-button-padding--large: rem-calc(0 20);
$header-search-button-active-padding--large: $header-search-button-padding--large;
$header-search-button-label-margin: rem-calc(0 70 0 0);
$header-search-button-label-active-margin: $header-search-button-label-margin;
$header-logo-max-width: rem-calc(80);
$header-sticked-logo-max-width: $header-logo-max-width;
$header-sticked-logo-max-width--large: rem-calc(64);
$header-logo-text-align: center;
$header-logo-justify-content: center;
$header-main-area-padding: rem-calc(0 5 1 5);
$header-sticked-search-button-icon-color: color(light);
$header-sticked-top-nav-link-title-level-1-color: color(dark);
$header-user-link-account-sticky-login-icon: user-light;
$header-user-link-account-sticky-login-icon-height: rem-calc(24);
$header-user-link-account-sticky-login-icon-width: rem-calc(18);
$header-user-link-account-sticky-login-icon-color: color(light);
$header-sticked-minicart-icon-color: color(light);
$header-sticked-background: color(dark);
$header-top-nav-item-separator-color: color(transparent);
$header-search-button-font: #{rem-calc(12)} / 1 $font-primary;
$header-top-nav-left-padding: rem-calc(0 5);
$header-top-nav-left-padding--large: rem-calc(20 0 0);
$header-top-nav-item-font-size--large: rem-calc(14);
$header-top-nav-item-font-weight--large: 300;
$header-sticked-top-nav-item-level-1-max-width--large: rem-calc(120);
$header-sticked-top-nav-link-title-level-1-color--large: color(light);
$header-sticked-top-nav-link-title-level-1-hover-color--large: $header-sticked-top-nav-link-title-level-1-color--large;
$header-top-nav-item-margin--large: rem-calc(0 0 0 10);
$header-hamburger-border--large: 1px solid color(dark);

// Header mobile elements order
$header-logo-order: 3;
$header-search-cta-order: 1;
$header-localization-order: 2;
$header-cart-order: 4;
$header-hamburger-order: 5;

$header-search-button-border-hover--large: 1px solid color(primary);
$header-search-button-color-hover--large: color(primary);
$header-search-button-icon-color-hover--large: color(primary);
$header-search-button-background-hover--large: color(light);

$header-search-button-icon-height: rem-calc(19);
$header-search-button-icon-width: rem-calc(19);

@import "@lora/04-layout/header";