$footer-social-title-font: bold #{rem-calc(14)} / 1.25 $font-primary-bold;
$footer-social-title-font--large: bold #{rem-calc(18)} / 1.125 $font-primary-bold;
$footer-social-title-text-transform--large: uppercase;
$footer-social-list-flex-wrap--large: wrap;
$footer-social-item-title-flex--large: 1 1 100%;
$footer-social-item-title-margin--large: rem-calc(0 0 20);
$footer-social-list-padding: rem-calc(20 0);
$footer-social-list: (
    facebook: (
        enabled: true,
        icon: facebook,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
    twitter: (
        enabled: true,
        icon: twitter,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    pinterest: (
        enabled: true,
        icon: pinterest,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    instagram: (
        enabled: true,
        icon: instagram,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    youtube: (
        enabled: true,
        icon: youtube,
        icon-height: rem-calc(23),
        icon-width: rem-calc(23)
    ),
    tiktok: (
        enabled: true,
        icon: tiktok,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    snapchat: (
        enabled: true,
        icon: snapchat,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    spotify: (
        enabled: true,
        icon: spotify,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
);

@import "@lora/05-components/footer/footer-social";