
                            $is-app_ng_anz-enabled: true;
                            $account-address-suggestion-color: color(light) !default;
$account-address-suggestion-z-index: 2 !default;
$account-address-suggestion-top: rem-calc(34) !default;
$account-address-suggestion-hover-color: color(primary-active) !default;

$account-address-suggestion-list: rem-calc(5);
$account-address-suggestion-remove-margin-top: rem-calc(10);

@mixin anzlayer-components-account-address-page {
    .c-account-address__suggestion-list {
        position: absolute;
        z-index: $account-address-suggestion-z-index;
        background: $account-address-suggestion-color;

        &.m-list {
            top: $account-address-suggestion-top;
        }
    }

    .c-account-address__suggestion {
        padding: $account-address-suggestion-list;
        border: rem-calc(1) color(border) solid;
        border-bottom: none;
        cursor: pointer;

        &:last-child {
            border-bottom: rem-calc(1) color(border) solid;
        }

        &:hover,
        &:focus {
            color: $account-address-suggestion-hover-color;
        }
    }

    .c-account-address__remove {
        margin-top: $account-address-suggestion-remove-margin-top;
    }
}
                        