$content-hero-title-color: color(dark);
$content-hero-title-color--large: color(light);
$content-hero-title-font: #{rem-calc(34)} / 1 $font-tertiary;
$content-hero-title-font--large: #{rem-calc(46)} / 1 $font-tertiary;
$content-hero-title-letter-spacing: 1px;
$content-hero-description-color: color(dark);
$content-hero-description-color--large: color(light);
$content-hero-description-font: 100 #{rem-calc(14)} / 1.29 $font-primary-bold;
$content-hero-description-font--large: 100 #{rem-calc(16)} / 1.25 $font-primary-bold;
$content-hero-border-bottom: none;
$content-hero-unstack-body-padding: rem-calc(15 0 50 0);
$content-hero-label-font: 300 #{rem-calc(12)} / 1.25 $PexicoMicroMono;

@import "@lora/05-components/content/content-hero";