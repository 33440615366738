$footer-background: color(dark);
$footer-background--large: $footer-background;
$footer-top-background: color(global-background);
$footer-newsletter-margin: rem-calc(0 -25);
$footer-newsletter-margin--large: rem-calc(50 0 0);
$footer-newsletter-padding: rem-calc(30 25);
$footer-bottom-border-top: 0;
$footer-bottom-border-top--large: 0;
$footer-social-margin--large: rem-calc(5 -10 25 0);
$footer-row-variants-order: 2;
$footer-middle-inner-border-bottom--large: 1px solid color(border);
$footer-row-item-indent: rem-calc(14);
$footer-row-item-first-padding-left: rem-calc(14);
$footer-row-item-last-padding-left: 0;
$footer-row-variants-margin: rem-calc(30 0 10);
$footer-row-variants-flex-direction: row-reverse;
$footer-top-border-top: 1px solid color(border);
$footer-newsletter-signup-title-font: 700 #{rem-calc(14)} $font-primary-bold;
$footer-newsletter-signup-title-text-transform: uppercase;
$footer-bottom-inner-padding: 0 0 rem-calc(60) 0;

@import "@lora/04-layout/footer";