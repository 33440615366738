$product-swatches-pdp-margin--large: 0 auto 1.25rem;
$product-swatches-pdp-order: 1;
$product-swatches-toggle-font: 500 #{rem-calc(14)} / 1.14 $font-primary;
$product-swatches-toggle-text-transform: none;
$product-swatches-toggle-border-bottom: none;
$product-swatches-toggle-text-decoration: underline;
$product-swatches-toggle-icon-color: color(dark);
$product-swatches-toggle-font--large: 500 #{rem-calc(14)} / 1 $font-primary;
$product-swatches-toggle-icon-margin-top: rem-calc(-4);
$product-swatches-toggle-less-color: color(dark);
$product-swatches-selected-font: #{rem-calc(16)} / #{rem-calc(20)} $font-primary;
$product-swatches-selected-font--large: #{rem-calc(14)} / #{rem-calc(18)} $font-primary;

@import "@lora/05-components/product/product-swatches";