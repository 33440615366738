//stylelint-disable lora/variable-pattern
/* stylelint-disable declaration-no-important, max-nesting-depth */

// Text sizes per style guide
$main-text-sizes: (
    36,
    30,
    24,
    18,
    16,
    14,
    12
);

$main-text-families: (
    "font-primary": $font-primary,
    "font-primary-bold": $font-primary-bold,
    "font-secondary": $font-secondary,
    "font-tertiary": $font-tertiary
) !default;

$main-text-weights: (
    light: 300,
    medium: 500,
    normal: normal,
    bold: bold
) !default;

@mixin master-helpers-text-family($font-families: $main-text-families) {
    @each $font-name, $font-family in $font-families {
        .h-text-#{$font-name} {
            font-family: $font-family !important;
        }
    }

    @each $font-name, $font-family in $font-families {
        @each $size in $breakpoint-classes {
            @include breakpoint($size only) {
                .h-text-#{$font-name}-for-#{$size} {
                    font-family: $font-family !important;
                }
            }
        }
    }
}

@mixin master-helpers-text-weight($font-weights: $main-text-weights) {
    @each $font-weight-name, $font-weight in $font-weights {
        .h-text-#{$font-weight-name} {
            font-weight: $font-weight !important;
        }
    }

    @each $font-weight-name, $font-weight in $font-weights {
        @each $size in $breakpoint-classes {
            @include breakpoint($size only) {
                .h-text-#{$font-weight-name}-for-#{$size} {
                    font-weight: $font-weight !important;
                }
            }
        }
    }
}

@mixin master-helpers-text-styles {
    .h-text-black-background {
        background: color(dark);
        color: color(light);
        margin: rem-calc(5) auto;
        display: inline-block;
        width: auto;

        @include text-style(
            $font: (
                small: bold #{rem-calc(24)} / 0.75 $font-primary-bold,
                large: bold #{rem-calc(36)} / 0.75 $font-primary-bold
            )
        );
    }
}

@import "@lora/02-helpers/text"; //stylelint-disable-line no-invalid-position-at-import-rule