$content-reassurance-padding--large: rem-calc(25 15);
$content-reassurance-image-margin: rem-calc(0 20 0 0);
$content-reassurance-image-margin--large: rem-calc(0 30 0 0);
$content-reassurance-fade: transparent;
$content-reassurance-text-color: color(dark);
$content-reassurance-text-font: 600 #{rem-calc(16)} / 1.25 $font-primary-bold;
$content-reassurance-text-font--large: 600 #{rem-calc(16)} / 1.25 $font-primary-bold;
$content-reassurance-text-text-transform: uppercase;
$content-reassurance-text-text-transform--large: uppercase;
$content-reassurance-description-font: 300 #{rem-calc(16)} / 1.25 $font-primary;
$content-reassurance-description-font--large: 300 #{rem-calc(16)} / 1.25 $font-primary;

@import "@lora/05-components/content/content-reassurance";