$swatch-sizes: (
    default: (
        swatch-size: rem-calc(30),
        border-size: rem-calc(2)
    ),
    small: (
        swatch-size: rem-calc(30),
        border-size: rem-calc(2)
    ),
    medium: (
        swatch-size: rem-calc(30),
        border-size: rem-calc(2)
    ),
    large: (
        swatch-size: rem-calc(45),
        border-size: rem-calc(4)
    ),
    x-large: (
        swatch-size: rem-calc(48),
        border-size: rem-calc(4)
    )
);
$swatch-selected-border-color: color(dark);
$swatch-radius: 100%;
$swatch-radius--large: $swatch-radius;
$swatch-disabled-icon-color: color(primary);
$swatch-padding: rem-calc(2);
$swatch-padding--large: $swatch-padding;
$swatch-border-width: rem-calc(1);
$swatch-selected-border-padding: $swatch-border-width solid color(border);

@import "@lora/05-components/swatch";