$promotion-approaching-discount-background: color(global-background);
$promotion-approaching-discount-margin: rem-calc(15 0 0);
$promotion-approaching-discount-font: 100 #{rem-calc(14)} / 1.29 $font-primary;
$promotion-approaching-discount-padding: rem-calc(16 20);
$promotion-product-background: color(global-background);
$promotion-product-color: color(dark);
$promotion-product-border: none;
$promotion-product-padding: rem-calc(8 20);
$promotion-product-font: 100 #{rem-calc(14)} / 1.29 $font-primary;
$promotion-product-tooltip-color: color(dark);
$promotion-product-tooltip-font: 500 #{rem-calc(14)} / 1.14 $font-primary;
$promotion-product-text-align: left;

// Message with checkmark
$promotion-applied-discount-icon-color: color(success);

// 3rd type of a promo message
$promotion-applied-discount-font: 500 #{rem-calc(12)} / 1.45 $font-primary;
$promotion-applied-discount-color: color(dark);

/// Coupon icon
$promotion-coupon-icon-size: rem-calc(16);
$promotion-coupon-icon-margin: rem-calc(1 5 0 0);

@import "@lora/05-components/promotion";