$content-advantage-icon-margin: rem-calc(0 0 10);
$content-advantage-flex-direction: column;
$content-advantage-text-line-height: 1;
$content-advantage-text-margin: rem-calc(28 0 0);
$content-advantage-title-font-weight: 500;
$content-advantage-title-font-size: rem-calc(16);
$content-advantage-text-font-size: rem-calc(16);
$content-advantage-text-color: color(organism-background);
$content-advantage-text-font-weight: 100;

@import "@lora/05-components/content/content-advantage";