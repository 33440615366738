$content-tile-title-font: 500 #{rem-calc(18)} / 1 $font-primary-bold;
$content-tile-title-font--large: $content-tile-title-font;
$content-tile-title-letter-spacing: 1px;
$content-tile-description-color: color(dark);
$content-tile-description-color--large: $content-tile-description-color;
$content-tile-description-font: 100 #{rem-calc(16)} / 1.25 $font-primary;
$content-tile-description-font--large: $content-tile-description-font;
$content-tile-actions-margin: rem-calc(15 0 30 0);
$content-tile-actions-margin--large: $content-tile-actions-margin;
$content-tile-title-margin: rem-calc(0 0 20);
$content-tile-title-margin--large: $content-tile-title-margin;
$content-tile-large-title-text-transform: uppercase;
$content-tile-large-label-text-transform: uppercase;
$content-tile-large-title-text-transform--large: $content-tile-large-title-text-transform;
$content-tile-large-description-font--large: 100 #{rem-calc(16)} / 1.25 $font-primary;
$content-tile-large-title-font: 700 #{rem-calc(24)} $font-primary-bold;
$content-tile-large-title-font--large: 700 #{rem-calc(36)} / 1 $font-primary-bold;
$content-tile-large-title-color: color(light);
$content-tile-large-title-background-color: color(text);
$content-tile-large-title-font-weight: 500;
$content-tile-large-title-padding: rem-calc(3 10);
$content-tile-large-title-width: auto;
$content-tile-large-title-display: inline-block;
$content-tile-large-label-font: 400 #{rem-calc(14)} / 1 $font-tertiary;
$content-tile-large-label-font--large: 400 #{rem-calc(14)} / 1 $font-tertiary;
$content-tile-large-description-font: 100 #{rem-calc(14)} / 1.5 $font-primary;
$content-tile-home-title-margin: rem-calc(5 0);
$content-tile-margin: rem-calc(0 0 30);

@mixin master-components-content-tile {
    .c-content-tile {
        &.m-large {
            .c-content-tile__content {
                .c-content-tile__label {
                    width: $content-tile-large-title-width;
                    display: $content-tile-large-title-display;
                    padding: $content-tile-large-title-padding;
                    color: $content-tile-large-title-color;
                    background: $content-tile-large-title-background-color;
                }
            }
        }
    }

    .l-homepage {
        @include breakpoint(medium down) {
            .c-content-tile__title {
                margin: $content-tile-home-title-margin;
            }

            .c-content-tile__description {
                font: $content-tile-large-description-font;
            }
        }
    }
}

@import "@lora/05-components/content-tile"; //stylelint-disable-line no-invalid-position-at-import-rule