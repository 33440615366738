$header-navigation-flyout-category-title-font: 500 rem-calc(16) $font-primary;
$header-navigation-flyout-category-title-text-transform: uppercase;
$header-navigation-flyout-category-link-color: color(organism-background);
$header-navigation-flyout-category-link-font: 300 rem-calc(16) $font-primary;
$header-navigation-flyout-category-link-text-decoration: none;
$header-navigation-flyout-push-title-color: color(dark);
$header-navigation-flyout-push-title-font: rem-calc(16) $font-primary;
$header-navigation-flyout-push-title-text-transform: uppercase;
$header-navigation-flyout-push-description-font: 300 rem-calc(16) $font-primary;

@import "@lora/05-components/header/header-navigation-flyout";