/// Default text transform for links.
/// @type String
$button-link-text-transform: uppercase;

/// Default text deocration for links.
/// @type String
$anchor-text-decoration: underline;

/// Default text decoration for links on hover.
/// @type String
$anchor-text-decoration-hover: underline;

@import "@lora/03-base/links";