$section-content-margin--large: rem-calc(18 0 0 0);
$section-padding--large: rem-calc(18 0);
$section-description-font: 100 #{rem-calc(16)} / 1.25 $font-primary;
$section-description-text-align--large: left;
$section-title-font: bold #{rem-calc(24)} / 1 $font-primary-bold;
$section-title-font--large: bold #{rem-calc(36)} / 1 $font-primary-bold;
$section-title-margin: rem-calc(10 0 20);
$section-title-margin--large: rem-calc(25 0);
$section-title-text-align--large: left;
$section-view-more-text-align: left;
$section-view-more-text-align--large: $section-view-more-text-align;
$section-view-more-link-font: 500 #{rem-calc(14)} / 1.14 $font-primary;
$section-view-more-link-font--large: 500 #{rem-calc(16)} / 1.25 $font-primary;
$section-view-more-link-text-transform: none;
$section-view-more-margin: rem-calc(5 0);
$section-secondary-title-font: bold #{rem-calc(24)} / 1 $font-primary-bold;
$section-secondary-title-font--large: $section-secondary-title-font;
$section-description-margin: rem-calc(0 0 37);
$section-title-secondary-text-transform: uppercase;

@import "@lora/05-components/section";