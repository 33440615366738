$form-select-size: (
    "small": rem-calc(50),
    "normal": $global-input-primary-height,
    "large": rem-calc(50)
);

// Default select
$form-select-font-size: rem-calc(14);
$form-select-border-radius: 0;
$form-select-text-transform: uppercase;
$form-select-height: rem-calc(50);
$form-select-label-float-margin: 0;
$form-select-panel-item-background-hover: color(global-background);
$form-select-panel-item-background-selected: color(global-background);

// Secondary
$form-select-secondary-arrow-color: color(light);

//Third
$form-select-third-background: color(dark);
$form-select-third-border-color: color(light);
$form-select-third-radius: 0;
$form-select-third-text-transform: none;

@import "@lora/05-components/form/select";