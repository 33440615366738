$header-suggestions-title-font--large: 500 #{rem-calc(18)} / 1 $font-primary;
$header-suggestions-title-text-transform--large: uppercase;
$header-suggestions-products-description-margin: rem-calc(11 0 5);
$header-suggestions-products-name-text-transform--large: uppercase;
$header-suggestions-products-price-font: 500 #{rem-calc(16)} / 1.25 $font-primary;
$header-suggestions-products-name-font--large: 500 #{rem-calc(18)} / 1 $font-primary;
$header-suggestions-products-description-color: color(organism-background);
$header-suggestions-products-description-font: 100 #{rem-calc(14)} / 1 $font-primary;
$header-suggestions-view-all-color: color(dark);
$header-suggestions-view-all-font: #{rem-calc(14)} / 1.14 $font-primary;
$header-suggestions-view-all-text-transform: none;
$header-suggestions-view-all-right: 5%;
$header-suggestions-view-all-top: 80%;
$header-suggestions-sections-separator: none;
$header-suggestions-text-color: color(organism-background);
$header-suggestions-text-font--large: 100 #{rem-calc(16)} / 1 $font-primary;
$header-suggestions-text-separator: 1px solid color(border);
$header-suggestions-text-separator--large: $header-suggestions-text-separator;
$header-suggestions-text-list-separator--large: none;
$header-suggestions-text-height--large: rem-calc(68);
$header-suggestions-products-name-color: color(organism-background);
$header-suggestions-main-secondary-width--large: rem-calc(730) !default;

@import "@lora/05-components/header/header-suggestions";