
                            $is-app_demo-enabled: true;
                            /*
* Demo settings file
*
* Should be used to enable related CSS for features, released as disabled by default.
* The feature CSS should be wrapped in a condition with the boolean type variable equal to `false !default`.
* The demo-settings file should have the variable overload equal to `true` (do not use `!default` flag here)
* Overloaded variables within this file will only affect the unbranded NGL website to not increase the CSS file size for brands not using a feature
 
Examples of usage:

// 05-components\form\_check-field.scss
/// Switch
$form-check-field-switch-enabled: false !default;

// _features.scss
$is-product-compare-enabled: false !default;

// 01-utilities\aos\_animations.scss
/// Fade animations
$aos-animation-fade-up-enabled: false !default;

// _settings.scss
$is-motion-enabled: false !default;

// _demo-settings.scss
$form-check-field-switch-enabled: true;
$is-product-compare-enabled: true;
$aos-animation-fade-up-enabled: true;
$is-motion-enabled: true;

*/

// 05-components\header\_header-navigation.scss
$header-navigation-item-level-2-view-all-display--xlarge: block;
                        