$form-label-color: color(dark);
$form-label-float-left: 0;
$form-label-float-padding: rem-calc(0 0 0 18);
$form-label-float-font-size: rem-calc(16);
$form-label-float-color: color(dark);
$form-label-float-focus-color: color(dark);
$form-label-float-focus-padding: rem-calc(0 20);
$form-label-float-focus-font-size: rem-calc(12);
$form-label-line-height: 1.43;
$form-label-font-weight: 300;

@import "@lora/05-components/form/label";