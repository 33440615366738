$form-row-field-margin: rem-calc(0 10 20);
$form-legend-font-size: rem-calc(11);
$form-legend-color: color(text-secondary);
$form-legend-margin: rem-calc(0 0 25);
$form-field-margin: rem-calc(0 0 20);
$form-row-title-font-size: rem-calc(16);
$form-row-title-font-weight: 300;
$form-aside-width: rem-calc(280);
$form-error-message-margin: rem-calc(-4) 0 rem-calc(15);

@import "@lora/05-components/form/form";